<template>
  <card-component class="bfy-column no-padding no-border no-background w-20">
    <b-button
      size="is-large"
      type="is-success"
      icon-left="check-decagram"
      class="change-status-button mb-15"
      :disabled="checkApprove"
      :loading="approveLoading"
      @click="setStatus(1)"
    >
      {{ $t('approve') }}
    </b-button>
    <b-button
      size="is-large"
      type="is-danger"
      class="change-status-button mt-10"
      icon-left="close-circle"
      :disabled="checkReject"
      :label="$t('reject')"
      @click="$emit('update:reject-modal-active', true)"
    />
  </card-component>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import TransferDetails from '@/views/TransferDetails'

export default {
  name: 'AdminPanel',
  components: { CardComponent },
  extends: TransferDetails,
  props: {
    data: {
      type: Object,
      default: null
    },
    setStatus: {
      type: Function,
      default: null
    },
    approveLoading: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
    }
  },
  computed: {
    checkApprove () {
      const status = this.data.status
      if (status != 0) {
        return true;
      }

      return false
    },
    checkReject () {
      const status = this.data.status

      if (status == 5 || status == 4 || status == 3) {
        return true;
      }

      return false
    }
  },
  methods: {
  },
}
</script>
