<template>
  <div class="content-area">
    <hero-bar>
      #{{ id }}
      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{'is-loading':isLoading}"
        @click="saveDetails"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>

    <b-loading :is-full-page="true" :active.sync="isFullLoading" :can-cancel="false" />

    <section v-if="data" class="section is-main-section transfer-details">
      <div class="columns">
        <!-- head details -->
        <card-component
          :title="$t('transferInfo')"
          :has-button-slot="true"
          icon="transit-transfer"
          class="mr-10 bfy-column w-50"
        >
          <a slot="button" :href="googleDirectionsURL" target="_blank" type="button" class="button">
            <b-tooltip slot="right" :label="$t('directions')" type="is-dark" position="is-bottom">
              <b-icon icon="directions" custom-size="mdi-24px" />
            </b-tooltip>
          </a>
          <b-field horizontal :label="$t('txtstatus')">
            <div>
              <span class="tag" :class="data.status | showStatus">
                {{ $t("status." + data.status) }}
              </span>
              <b-button
                v-if="(checkPermission('set_driver') || checkUser) && isVoidable"
                size="is-small"
                type="is-danger"
                class="ml-10"
                icon-left="close-circle"
                :loading="isLoadingReject"
                @click="isRejectModalActive = !isRejectModalActive"
              >
                {{ $t('cancelRequest') }}
              </b-button>

              <b-button
                v-if="(checkPermission('set_driver') || checkUser) && isVoidable"
                size="is-small"
                type="is-info"
                class="ml-10"
                icon-left="arrow-right-bold-circle"
                :loading="isLoadingMoveShttle"
                @click="isMoveShuttleModalActive = !isMoveShuttleModalActive"
              >
                {{ $t('moveShuttle') }}
              </b-button>
            </div>
          </b-field>
          <b-field horizontal :label="$t('start')">
            {{ data.start_address }}
          </b-field>
          <b-field horizontal :label="$t('target')">
            {{ data.target_address }}
          </b-field>
          <b-field horizontal :label="$t('tranferDate')" class="nowrap">
            <b-tooltip :label="data.transfer_date" type="is-dark" position="is-bottom">
              <span>{{ data.transfer_date | formatDate }}</span>
            </b-tooltip>
          </b-field>
          <b-field horizontal :label="$t('fullName')" class="nowrap">
            <span>{{ data.user.data.attributes.name }}</span>
          </b-field>
          <b-field horizontal :label="$t('phone')">
            <a :href="'tel:' + data.user.data.attributes.phone">{{ data.user.data.attributes.phone | phone }}</a>
          </b-field>
          <b-field v-if="data.status == 5 && data.reject_comment" horizontal :label="$t('rejectComment')">
            {{ data.reject_comment }}
          </b-field>
          <b-field
            v-if="data.status == 5 && data.reject_user"
            horizontal
            :label="$t('rejectUser')"
          >
            {{ data.reject_user.data.attributes.name }}
            <span class="emptyText">({{ data.reject_user.data.attributes.role }})</span>
          </b-field>
        </card-component>

        <card-component
          v-if="checkPermission('set_driver')"
          :title="$t('driverCarInfo')"
          icon="account-card-details-outline"
          class="mr-10 bfy-column w-40"
        >
          <b-field horizontal :label="$t('driver')">
            <b-autocomplete
              ref="driverInput"
              v-model="fakeDriver"
              :disabled="data.self_drive"
              field="name"
              :data="drivers"
              :placeholder="$t('chooseDriver')"
              :loading="isDriverFetching"
              open-on-focus
              @typing="getAsyncDriverData"
              @select="selectDriver"
            >
              <div slot-scope="props">
                <div class="media">
                  <div class="media-content" :class="{ 'has-text-danger': !props.option.has_shift }">
                    <b>{{ props.option.name }}</b>
                    <br>
                    <small>
                      {{ props.option.phone | phone }}
                    </small>
                  </div>
                </div>
              </div>
            </b-autocomplete>
          </b-field>

          <b-field horizontal :label="$t('carTxt')">
            <b-autocomplete
              v-model="fakeCarPlate"
              field="plate"
              :data="cars"
              :placeholder="$t('chooseCar')"
              :loading="isCarFetching"
              @typing="getAsyncCarData"
              @select="selectCar"
            >
              <template slot-scope="props">
                <span>
                  {{ props.option.plate }}
                  <span class="emptyText">({{ props.option.brand }} - {{ props.option.model }})</span>
                </span>
              </template>
            </b-autocomplete>
          </b-field>

          <b-field horizontal :label="$t('driverPhone')" class="nowrap">
            <b-input
              :value="driver.phone | phone"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field horizontal :label="$t('estimatedTime')" class="nowrap">
            <b-timepicker
              v-model="estimatedTime"
              :increment-minutes="minutesGranularity"
              :min-time="estimatedMinTime"
              :placeholder="$t('estimatedTime')"
              icon="clock"
              editable
            />
          </b-field>

          <template v-if="data.status === 4">
            <b-field horizontal label="Başlangıç KM" class="nowrap">
              <b-input
                v-model="data.start_km"
                :disabled="!hasRole('Lead Driver')"
                placeholder="Başlangıç KM"
                class="is-fullwidth"
              />
            </b-field>
            <b-field horizontal label="Bitiş KM" class="nowrap">
              <b-input
                v-model="data.end_km"
                :disabled="!hasRole('Lead Driver')"
                placeholder="Bitiş KM"
                class="is-fullwidth"
              />
            </b-field>
            <b-field horizontal label="Yapılan KM" class="nowrap">
              <b-input
                :value="(data.end_km - data.start_km) || null"
                disabled
                class="is-fullwidth"
              />
            </b-field>
          </template>

          <b-message
            v-for="(suggestion, index) in suggestions.cars"
            :key="index"
            type="is-info"
            class="message-p10"
          >
            Varış noktasına {{ parseInt(suggestion.dist) }} metre uzaklıkta
            <router-link
              :to="{ name: 'transfers.show', params: { id: suggestion.id }}"
              target="_blank"
            >
              #{{ suggestion.id }}
            </router-link>
            nolu istekte {{ suggestion.car_plate }} ({{ suggestion.driver.name }}) aracı {{ suggestion.estimated_time | showOnlyTime }}'de boşa çıkacaktır.
            <a href="#" @click.prevent="setDriverAndCar(suggestion.driver, suggestion.car_plate)">Bu sürücü ve plakayı atamak için tıklayın.</a>
          </b-message>
        </card-component>
        <card-component
          v-else
          :title="$t('driverCarInfo')"
          icon="account-card-details-outline"
          class="mr-10 bfy-column w-30"
        >
          <b-field :label="$t('driver')">
            <b-input
              :value="driver.attributes.name"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field :label="$t('driverPhone')">
            <b-input
              :value="driver.attributes.phone | phone"
              custom-class="is-static"
              readonly
            />
          </b-field>

          <b-field :label="$t('plate')">
            {{ data.car_plate }}
            <span v-if="data.car" class="emptyText">
              ({{ data.car.brand }} - {{ data.car.model }})
            </span>
          </b-field>
        </card-component>

        <driver-panel
          v-if="checkDriver"
          :approve-loading="isLoadingApproval"
          :set-status="setStatus"
          :data="data"
        />

        <admin-panel
          v-if="checkAdmin"
          :reject-modal-active.sync="isRejectModalActive"
          :approve-loading="isLoadingApproval"
          :set-status="setStatus"
          :data="data"
        />
      </div><!-- head details -->
      <!-- Details -->
      <card-component icon="account-card-details" class="mb-3">
        <tiles>
          <b-field :label="$t('createdDate')">
            <span>{{ data.created_at | formatDate }}</span>
          </b-field>
        </tiles>
        <tiles>
          <b-field :label="$t('userComment')" class="is-fullwidth">
            <b-input
              v-model="data.user_comment"
              :value="data.user_comment"
              :readonly="data.status != 0"
              type="textarea"
              maxlength="200"
              custom-class="is-static is-fullwidth"
            />
          </b-field>
          <b-field
            v-if="checkPermission('write_admin_note')"
            :label="$t('adminComment')"
            class="is-fullwidth"
          >
            <b-input
              v-model="data.admin_comment"
              :value="data.admin_comment"
              type="textarea"
              maxlength="200"
              custom-class="is-static is-fullwidth"
            />
          </b-field>
          <b-field
            v-else-if="checkPermission('see_admin_note')"
            :label="$t('adminComment')"
          >
            <b-input :value="data.admin_comment" custom-class="is-static" readonly />
          </b-field>
        </tiles>
      </card-component>
      <card-component v-if="hasRole('Super Admin', 'Lead Driver') && data.status === 4" :title="$t('reviews')" class="mb-3">
        <b-table v-if="data.reviews.length" :data="data.reviews" class="no-wrap">
          <b-table-column v-slot="props" :label="$t('author')" field="author">
            {{ props.row.author.name }}
            <b-tag class="is-size-7" rounded>
              {{ props.row.author.id === data.driver_id && props.row.author.role === 'Driver' ? 'Sürücü' : 'Yolcu' }}
            </b-tag>
          </b-table-column>
          <b-table-column v-slot="props" :label="$t('rating')" field="rating">
            <b-icon v-for="i in props.row.rating" :key="'star' + i" icon="star" size="is-small" type="is-primary" />
            <b-icon v-for="i in (5 - props.row.rating)" :key="'star-outline' + i" icon="star-outline" size="is-small" type="is-primary" />
          </b-table-column>
          <b-table-column v-slot="props" :label="$t('description')" field="body">
            {{ props.row.body }}
          </b-table-column>
          <b-table-column v-slot="props" :label="$t('date')" field="created_at">
            {{ props.row.created_at | formatDateTimeYear }}
          </b-table-column>
        </b-table>
        <template v-else>
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="table" size="is-large" />
            </p>
            <p>{{ $t('nodata') }}</p>
          </div>
        </template>
      </card-component><!-- End Details -->
      <!-- Additional Passengers -->
      <div v-if="data.passengers.length > 0" class="flex-left">
        <div
          v-for="(passenger, index) in data.passengers"
          :key="index"
          class="passenger_box"
        >
          <card-component
            :title="(index + 1) +'. '+ $t('passenger')"
          >
            <b-field :label="$t('fullname')">
              <span> {{ passenger.name }} </span>
            </b-field>
            <b-field :label="$t('phoneNumber')">
              <a :href="'tel:' + passenger.phone">{{ passenger.phone | phone }}</a>
            </b-field>
          </card-component>
        </div>
      </div>
      <!-- / Additional Passengers -->
    </section>
    <b-modal
      :active.sync="isRejectModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <RejectModal
        :id="id"
        :button-text="checkAdmin ? $t('reject') : $t('cancelRequest')"
      />
    </b-modal>
    <b-modal
      :active.sync="isMoveShuttleModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: 700px">
        <ShuttleRegister
          :isModal="true"
          :transferDetails="data"
          :transferId="id"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import moment from 'moment'

import AdminPanel from '@/components/TransferPanels/AdminPanel'
import DriverPanel from '@/components/TransferPanels/DriverPanel'

export default {
  name: 'TransferDetails',
  title: ({ $i18n }) => $i18n.t('titles.transfer'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    Tiles: () => import('@/components/Tiles'),
    RejectModal: () => import(/* webpackPrefetch: true */ '@/components/RejectModal'),
    ShuttleRegister: () => import(/* webpackPrefetch: true */ '@/views/ShuttleRegister'),
    AdminPanel,
    DriverPanel,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return parseInt(this.$route.params.id)
      }
    },
    driverId: {
      type: Number,
      default: function () {
        return parseInt(this.$route.query.driver_id);
      }
    },
    carId: {
      type: Number,
      default: function () {
        return parseInt(this.$route.query.car_id);
      }
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: null,
      isRejectModalActive: false,
      isMoveShuttleModalActive: false,
      driver: { attributes: {phone: '', name: ''}},
      isDriverFetching: false,
      drivers: [],
      isCarFetching: false,
      cars: [],
      fakeCarPlate: '',
      fakeDriver: '',
      isFullLoading: true,
      isLoading: false,
      isLoadingReject: false,
      isLoadingApproval: false,
      isLoadingMoveShttle: false,
      changeDisable: false,
      estimatedTime: '',
      minutesGranularity: 5,
      estimatedMinTime: null,
      suggestions: [],
      start_km: null,
      end_km: null,
    }
  },
  computed: {
    checkAdmin () {
      if (!this.$store.getters.authUser.attributes) {
        return false;
      }
      return this.$store.getters.authUser.attributes.role == 'Admin'
    },
    checkApproveReject () {
      if (!this.$store.getters.authUser.attributes) {
        return false;
      }

      const checkStatus = this.data.status == 2

      return this.checkAdmin && checkStatus
    },
    checkDriver () {
      if (!this.$store.getters.authUser.attributes) {
        return false;
      }

      return this.$store.getters.authUser.attributes.role == 'Driver'
    },
    checkUser () {
      if (!this.$store.getters.authUser.attributes) {
        return false;
      }

      return this.$store.getters.authUser.attributes.role == 'User'
    },
    checkReject () {
      let result = ''
      if (!this.$store.getters.authUser.attributes) {
        result = false
      }

      if (this.$store.getters.authUser.attributes.role == 'User') {
        if (this.data.status == 5 || this.data.status == 3 || this.data.status == 4) {
          result = true
        }
      }

      if (this.$store.getters.authUser.attributes.role == 'Admin') {
        if (this.data.status == 5 || this.data.status == 3 || this.data.status == 4 || this.data.status == 2) {
          result = true
        }
      }
      return result
    },
    googleDirectionsURL () {
      let url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&';
      url += `origin=${this.data.start_location}&destination=${this.data.target_location}`

      return url;
    },
    isVoidable () {
      return this.data.status != 5 && this.data.status != 4 && this.data.status != 3
    }
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  mounted () {
    this.loadTransferDetails()
    this.$store.commit('setAsideActiveMenuKey', 'transfers.index')
  },
  methods: {
    setDriverAndCar (driver, car_plate) {
      this.fakeDriver = driver.name
      this.driver = {
        attributes: driver,
        id: driver.id,
        type: 'User',
      }
      this.fakeCarPlate = car_plate
      this.data.car_plate = car_plate
    },
    loadTransferDetails () {
      this.showSpinner();
      this.$http.get(`transfer/${this.id}`)
        .then(({ data }) => {
          this.data = data.data.attributes;
          this.fakeCarPlate = this.data.car_plate;

          if (data.data.attributes.driver) {
            this.driver = data.data.attributes.driver.data;
            this.fakeDriver = this.driver.attributes.name;
          }
          this.estimatedMinTime = moment(data.data.attributes.transfer_date).toDate();

          if (data.data.attributes.estimated_time) {
            this.estimatedTime = moment(data.data.attributes.estimated_time).toDate();
          } else {
            // let minute = moment(data.data.attributes.transfer_date).format('m')
            // minute = (minute % 5)
            // this.estimatedTime = moment(data.data.attributes.transfer_date)
            //   .subtract(minute, 'minutes')
            //   .add(60, 'minutes')
            //   .toDate()
            this.estimatedTime = null;
          }

          if (data.data.attributes.status == 5) {
            this.changeDisable = true
          }

          this.$nextTick(() => {
            this.checkSuggestions()
            this.loadCarDriver()
            this.hideSpinner()
            this.isFullLoading = false
          });
      });
    },
    getAsyncDriverData: debounce(function (name) {
      this.drivers = []

      if (!name.length) {
        return
      }

      this.isDriverFetching = true

      this.$http.get('available-drivers', {
        params: { name, date: this.data.transfer_date }
      }).then(({ data }) => {
        this.drivers = data.data
      }).catch(() => {
        this.drivers = []
      }).finally(() => {
        this.isDriverFetching = false
      })
    }, 500),
    getAsyncCarData: debounce(function (q) {
      this.cars = []

      q = q.trim()

      if (q.length === 0) {
        return
      }

      this.isCarFetching = true

      this.$http.get('list-cars', { params: { q } }).then(({ data }) => {
        this.cars = data
      }).catch(() => {
        this.cars = []
      }).finally(() => {
        this.isCarFetching = false
      })
    }, 500),
    selectDriver (driver) {
      if (!driver) {
        return
      }

      this.driver = driver
      this.drivers = []

      this.$http.post('check-driver', {
        driver_id: driver.id,
        transfer_id: this.id,
      }).then(({ data }) => {
        if (data.count > 0) {
          this.$buefy.toast.open({
            message: this.$t('driverWarning', [data.count]),
            type: 'is-warning',
            duration: 5000,
            queue: false,
          })
        }
      })

      /* if (driver.has_shift) {
        this.driver = driver
        this.drivers = []

        return
      }

      this.$buefy.dialog.confirm({
        message: 'Seçilen sürücünün vardiyası bu iş için uygun değil, yine de atama yapmak istiyor musunuz?',
        confirmText: 'Evet',
        cancelText: 'Hayır',
        onConfirm: () => {
          this.driver = driver
          this.drivers = []
          // this.fakeDriver = driver.attributes.name

          this.$http.post('check-driver', {
            driver_id: driver.id,
            transfer_id: this.id
          }).then(({ data }) => {
              if (data.count > 0) {
                this.$buefy.toast.open({
                  message: this.$t('driverWarning', [data.count]),
                  type: 'is-warning',
                  duration: 5000,
                  queue: false
                });
              }
          })
        },
        onCancel: () => {
          this.fakeDriver = ''
          this.$refs.driverInput.focus()
        }
      }) */
    },
    selectCar (option) {
      if (option) {
        this.data.car_plate = option.plate

        this.cars = []

        this.$http.get('cars/check', { params: { plate: option.plate, transfer_id: this.id }}).then(({ data }) => {
          if (data.count > 0) {
            this.$buefy.toast.open({
              message: this.$t('carWarning', [data.count]),
              type: 'is-warning',
              duration: 5000,
              queue: false,
            })
          }
        }).catch(() => {
          this.$buefy.toast.open({
            message: this.$t('error'),
            type: 'is-danger',
            duration: 5000,
            queue: false,
          })
        })
      }
    },
    setStatus (status) {
      if (status == 1 || status == 3 || status == 4) {
        this.isLoadingApproval = true
      }
      this.isLoading = true;

      this.$http.put('set-status', {
        id: this.id,
        status: status,
      }).then(r => {
        if (r.data.success) {
          this.data.status = r.data.status;

          if (this.isLoadingApproval) {
            this.$buefy.toast.open({
              message: this.$t('approved'),
              type: 'is-success',
              queue: false
            });
            this.isLoadingApproval = false;
          }

          if (r.data.status == 4 || r.data.status == 1) {
           this.goBack()
          }
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Error: ${e.message}',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: true
        })
        this.$buefy.toast.open({
          message: 'Error:',
          type: 'is-danger',
          queue: false
        })
      });
    },
    saveDetails () {
      this.isLoading = true;
      this.saveAction();
    },
    saveAction (status) {
      if (!this.driver) {
        this.driver = { id: null };
      }

      let estimatedTime = null;
      if (this.estimatedTime) {
        const transferDate = moment(this.data.transfer_date).format('YYYY-MM-DD').toString()
        estimatedTime = moment(this.estimatedTime).format(`${transferDate} HH:mm`).toString()
      }

      this.$http.put('edit-transfer', {
          id: this.id,
          driver_id: this.driver.id,
          car_plate: this.data.car_plate,
          status: status,
          user_comment: this.data.user_comment,
          admin_comment: this.data.admin_comment,
          estimated_time: estimatedTime,
          start_km: this.data.start_km,
          end_km: this.data.end_km,
        }).then(r => {
          if (r.data.success) {
            if (this.isLoading) {
              this.$buefy.toast.open({
                message: this.$t('updated'),
                type: 'is-success',
                queue: false
              });

              this.goBack()

            } else if (this.isLoadingApproval) {
              this.$buefy.toast.open({
                message: this.$t('approved'),
                type: 'is-success',
                queue: false
              });

              this.goBack()

            } else if (this.isLoadingReject) {
              this.$buefy.toast.open({
                message: this.$t('canceled'),
                type: 'is-warning',
                queue: false
              });

              this.goBack()
            }

            if (r.data.status) {
              this.data.status = r.data.status;
            }
          } else {
            if (r.data.status) {
              this.data.status = r.data.status;

              if (r.data.status == 5) {
                this.changeDisable = true
              }
              this.data.reject_comment = r.data.reject_comment
              this.data.reject_user = {
                data: {
                  attributes: {
                    name: r.data.reject_user.name,
                    role: r.data.reject_user.roles[0].name
                  }
                }
              }
            }
            this.$buefy.toast.open({
              message: this.$t('errorApproved'),
              type: 'is-danger',
              queue: false
            })
          }
        })
        .catch(e => {
          this.$buefy.notification.open({
            duration: 5000,
            message: `Error: ${e.message}`,
            position: 'is-bottom-right',
            type: 'is-danger',
            hasIcon: true
          })
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger',
            queue: false
          })
        })
        .finally(() => {
          this.isLoadingApproval = false;
          this.isLoadingReject = false;
          this.fullLoading = false;
        });
    },
    checkSuggestions () {
      if (!this.checkPermission('set_driver') || this.data.status != 1) {
        return;
      }

      this.$http.get(`suggestions/${this.id}`)
        .then(({ data }) => {
          this.suggestions = data;
        })
    },
    loadCarDriver () {
      if (this.driverId) {
        this.isDriverFetching = true
        this.$http.get(`users/${this.driverId}`)
          .then(({ data }) => {
            this.fakeDriver = data.data.attributes.name;
            this.selectDriver(data.data)
          })
          .finally(() => {
            this.isDriverFetching = false
          })
      }

      if (this.carId) {
        this.isCarFetching = true
        this.$http.get(`cars/${this.carId}`).then(({ data }) => {
          this.fakeCarPlate = data.data.plate
          this.selectCar(data.data)
        }).finally(() => {
          this.isCarFetching = false
        })
      }
    },
    goBack () {
      if (this.isModal) {
        this.$store.commit('boardRefresh', true)
        this.$parent.close();
      } else {
        this.$router.push({ name:'transfers.index' });
      }
    },
  }
}
</script>
