<template>
  <card-component class="bfy-column no-padding no-border no-background w-20">
    <b-button
      size="is-large"
      type="is-success"
      icon-left="airplane-takeoff"
      class="change-status-button mb-15"
      :disabled="checkAldim"
      :loading="approveLoading"
      @click="setStatus(3)"
    >
      {{ $t('aldim') }}
    </b-button>
    <b-button
      size="is-large"
      type="is-purble"
      class="change-status-button mt-10"
      icon-left="airplane-landing"
      :disabled="checkBiraktim"
      :loading="approveLoading"
      @click="setStatus(4)"
    >
      {{ $t('biraktim') }}
    </b-button>
  </card-component>
</template>
<script>
import CardComponent from '@/components/CardComponent'
import TransferDetails from '@/views/TransferDetails'

export default {
  name: 'AdminPanel',
  components: { CardComponent},
  extends: TransferDetails,
  props: {
    data: {
      type: Object,
      default: null
    },
    setStatus: {
      type: Function,
      default: null
    },
    approveLoading: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
    }
  },
  computed: {
    checkAldim () {
      const status = this.data.status
      if (status != 2) {
        return true;
      }

      return false
    },
    checkBiraktim () {
      const status = this.data.status
      if (status != 3) {
        return true;
      }

      return false
    }
  },
  methods: {
  },
}
</script>
